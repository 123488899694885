<template>
  <page :loading="loading">
    <template v-slot:left>
      <logo />
    </template>
    <template v-slot:right>
      <div style="max-width: 500px">
        <div class="text-h5 font-weight-bold">We've sent an email to</div>
        <div class="text-title secondary--text font-weight-medium">
          {{ registerEmail }}
        </div>
        <div class="text-body-2 mt-10 ml-1 mb-1 font-weight-bold">
          Enter verification code
        </div>
        <div class="input-wrapper mt-2">
          <PincodeInput v-model="code" />
        </div>
        <div class="mt-10">
          <v-btn
            color="secondary"
            rounded
            dark
            class="black--text font-weight-bold"
            @click="submit"
            :disabled="!canSubmit"
            ><span class="px-10">Verify</span></v-btn
          >
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PincodeInput from "vue-pincode-input";

export default {
  name: "Verify",
  components: {
    PincodeInput,
  },
  data() {
    return {
      code: "",
      email: null,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    loading() {
      return this.account.loading;
    },
    canSubmit() {
      return this.code && this.code.length == 4;
    },
    registerEmail() {
      return this.account.register.email;
    },
  },
  methods: {
    ...mapActions({
      verifyUser: "account/verifyLoginCode",
      setLoading: "account/setLoading",
    }),
    async submit() {
      var payload = {
        code: this.code,
        email: this.email || this.registerEmail,
      };
      // console.log((payload);
      var err = await this.verifyUser(payload);
      this.error = err;
      if (err) {
        this.code = "";
      }
    },
  },
  beforeMount() {
    this.setLoading(false);
    var email = this.$route.query.email;
    if (email) {
      this.email = email;
    }
    var code = this.$route.query.code;
    if (code) {
      this.code = code;
    }
  },
};
</script>

<style>
div.vue-pincode-input-wrapper {
  /* background-color: red; */
}

input.vue-pincode-input {
  /* background-color: white; */
  color: white;
  font-size: 30px;
  aspect-ratio: 1;
  border: 3px solid var(--v-secondary-base);
}
</style>

